import React from 'react';
import PropTypes from 'prop-types';

export default class Loader extends React.Component {
    componentWillUnmount() {
        this.enableScroll();
    }

    getStyle() {
        const { fullscreen, isLoading } = this.props;
        if (fullscreen || isLoading) {
            this.disableScroll();
            return {
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 9998,
            };
        }

        this.enableScroll();
        return {
            position: 'relative',
        };
    }

    documentBody() {
        return document.body;
    }

    disableScroll() {
        const documentBody = this.documentBody();
        if (documentBody) {
            documentBody.style.setProperty('overflow', 'hidden');
        }
    }

    enableScroll() {
        const documentBody = this.documentBody();
        if (documentBody) {
            documentBody.style.removeProperty('overflow');
        }
    }

    render() {
        const { className, loading, fullscreen, text, error, retry, timedOut, children } = this.props;
        const { t } = this.context;
        if (error) {
            return (
                <div>
                    {t("Loader.ERROR")}
                    <button onClick={retry} className="button button-calm button-block">
                        {t("Loader.RETRY")}
                    </button>
                </div>
            );
        }
        if (timedOut) {
            return (
                <div>
                    {t("Loader.TAKING_LONG_TIME")}
                    <button onClick={retry} className="button button-calm button-block">
                    {t("Loader.RETRY")}
                    </button>
                </div>
            );
        }

        return (
            <div style={this.getStyle()} className={className}>
                {loading && (
                    <div
                        style={{
                            display: 'block',
                            position: 'absolute',
                            zIndex: 657,
                            backgroundColor: 'rgba(255, 255, 255, 0.901961)',
                            margin: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                        }}>
                        <div
                            className={'deb-loader--spinner ' + (fullscreen ? 'is-full-screen' : '')}
                            style={{
                                position: 'absolute',
                                display: 'inline-block',
                                left: 0,
                            }}>
                            <svg className="deb-loader--item__circular" viewBox="25 25 50 50">
                                <circle className="deb-loader--item__path" cx="50" cy="50" r="20" fill="none" />
                            </svg>
                            {text && <p className="deb-loader--text">{text}</p>}
                        </div>
                    </div>
                )}
                {children}
            </div>
        );
    }
}

Loader.propTypes = {
    error: PropTypes.bool,
    timedOut: PropTypes.bool,
    isLoading: PropTypes.bool,
    loading: PropTypes.bool,
    fullscreen: PropTypes.bool,
    retry: PropTypes.func,
    text: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element,
        PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Loader.defaultProps = {
    loading: true,
    fullscreen: false
};
