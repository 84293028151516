import {
    browserVersion,
    isAndroid,
    isBrowser,
    isChrome,
    isEdge,
    isFirefox,
    isIE,
    isOpera,
    isSafari, isSamsungBrowser
} from "react-device-detect";

export const checkWebVersioning = () => {
    return !(isIE || (isChrome && browserVersion < 56) || (isEdge && browserVersion < 79) || (isSafari && browserVersion < 11)
        || (isFirefox && browserVersion < 44) || (isOpera && browserVersion < 43));

}

export const checkMobileVersioning = () => {
    return !((isChrome && browserVersion < 103) || (isSafari && browserVersion < 11) || (isAndroid && browserVersion < 103)
        || (isFirefox && browserVersion < 101) || (isOpera && browserVersion < 64) || isSamsungBrowser);
}

export const isVonageCompatible = () => {
    if(isBrowser){
        return checkWebVersioning();
    }else{
        return checkMobileVersioning();
    }
}
