import { NotificationManager } from 'react-notifications';
import { isSafari } from "react-device-detect";

let nativeNotificationsEnabled = false;
let subscribedToWebPush = false;

const onNotificationPermissionResolved = (st)=>{
    if (st === "granted") {
        nativeNotificationsEnabled = true;
        console.debug("Local notifications were enabled");
    }
};

const askForLocalNotificationPermission = () => {
    if (nativeNotificationsEnabled) {
        return;
    }
    if ('Notification' in window && Notification.requestPermission) {
        if (Notification.requestPermission() instanceof Promise) {
            Notification.requestPermission().then(onNotificationPermissionResolved);
        } else {
            Notification.requestPermission(onNotificationPermissionResolved);
        }
    }
};

const showNotificationFromServiceWorker = function(title, options, registration) {
    if (registration) registration.showNotification(title, options);
};

const validateLocalNotificationDoesNotExist = (id, registration) => {
    return new Promise((resolve, reject) => {
        if (!registration) {
            reject("Couldn't get service worker registration");
            return;
        }
        registration.getNotifications().then((notifications)=>{
            const hasRepeatedNotifications = notifications.some((el) => {
                return el.data.id === id
            });
            if (hasRepeatedNotifications) {
                reject();
            } else {
                resolve(registration);
            }
        });
    });
};

const showNotification = (type, message, title, timeOut, callback, priority, id) => {
    if (nativeNotificationsEnabled && !subscribedToWebPush) {
        // TODO: Build options object according to parameters (esp. type)
        const options = {
            body: message,
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            data: {
                id: id
            },
            badge: "/app/static/logo.png"
        };
        // This worked on desktop, through real https (not localhost)
        navigator.serviceWorker.getRegistration().then( reg => {
            validateLocalNotificationDoesNotExist(id, reg)
                .then(showNotificationFromServiceWorker.bind(this, title, options));
        });
    } else {
        NotificationManager[type](message,title,timeOut,callback,priority);
    }
};

const info = showNotification.bind(this,"info");
const warning = showNotification.bind(this,"warning");
const error = showNotification.bind(this,"error");
const success = showNotification.bind(this,"success");

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function subscribeUser(vapidPublicKey) {
    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

    return new Promise((resolve, reject)=>{

        if ((!('serviceWorker' in navigator) && 'PushManager' in navigator) || isSafari) {
            reject("Push notifications are not available in this browser");
            return;
        }
        navigator.serviceWorker.getRegistration(window.location.pathname).then((reg)=>{
            console.log("serviceWorkerReady", reg);
            reg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: convertedVapidKey
            })
                .then((sub)=>{
                    subscribedToWebPush = true;
                    resolve(sub);
                })
                .catch((e) => {
                    if (Notification.permission === 'denied') {
                        reject("Permission for notifications was denied");
                    } else {
                        if (typeof e === "function") {
                            console.debug(e());
                        } else {
                            console.debug(e);
                        }
                        reject('Unable to subscribe to push');
                    }
                });
        });
    });
}

export const subscribeToWebPush = subscribeUser;

export {info, warning, error, success};

export {askForLocalNotificationPermission};
