import React, { Component } from 'react';
import imgFinal from '../img/final.png';
import { AppConsumer, AppContext } from '../components/AppContext';

class OfflineView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            online: true
        };

        this.changeOnline = this.changeOnline.bind(this);
    }

    changeOnline() {
        this.setState({online: !!window.navigator.onLine});
        if(!!window.navigator.onLine) {
            if(this.context.state.actualTurnStatus !== "VIDEOCALL_END") {
                this.context.actions.setTurnStatus("EMPTY");
            }
        } else {
            this.context.actions.setTurnStatus("LOST_CONNECTION");
        }
    }

    componentDidMount() {
        window.addEventListener('online', this.changeOnline, false);
        window.addEventListener('offline', this.changeOnline, false);
        this.changeOnline();
    }

    componentWillUnmount() {
        window.removeEventListener('online',  this.changeOnline);
        window.removeEventListener('offline',  this.changeOnline);
    }

    render() {
        return (
                <AppConsumer>
                {({state, t}) => (
                    <>
                        {
                               !this.state.online &&
                               <div className="offlineView">
                                   <img src={imgFinal} alt={t("OfflineView.OFFLINE_IMAGE")} />
                                   <div className="offlineView__texts">
                                       <h1>{t("OfflineView.OFFLINE_TITTLE")}</h1>
                                       <p>{t("OfflineView.OFFLINE_TEXT")}</p>
                                   </div>
                               </div>
                        }
                    </>
                )}
                </AppConsumer>
        );
    }
}
OfflineView.contextType = AppContext;
export default OfflineView;
