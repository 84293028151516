import React, {Component} from 'react';
import img_error from '../img/img_error.png';
import {AppConsumer, AppContext} from "../components/AppContext";
import TopBar from "../components/TopBar";
import OfflineView from "./OfflineView";

class NoBranchesAvailable extends Component {

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);       
    }

    /**
     * Go to the search view
     */
     goBack() {
        this.props.history.push('/tramites');
    }

    render() {
        return (
            <AppConsumer>
                {({t}) => (
                    <div className="turnView">
                        <TopBar
                            title={this.context.state.selectedCompany}
                            linkBack={"/tramites"}
                        />
                        <div className="content finished">
                            <div className="viewSelectQueue__imgContainer">
                                <div className="card card-info">
                                    <img alt={t("NoBranchesAvailable.NO_BRANCHES_AVAILABLE_IMAGE")} src={img_error}
                                         className={'img-responsive'}/>
                                    <h2 className="deb-main-title"
                                        id="noBranchesAvailableTitle">{t("NoBranchesAvailable.NO_BRANCHES_AVAILABLE_TITLE")}</h2>
                                        <button className="button button-calm button-block button-primary"
                                            id="goBackBtn"
                                            onClick={this.goBack}>
                                         {t("TurnView.BACK")}
                                        </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </AppConsumer>
        );
    }
}

NoBranchesAvailable.contextType = AppContext;
export default NoBranchesAvailable;