import NodeRSA from "node-rsa"

export const encrypt = (toEncrypt, publicKeyStr) => {
    var key = new NodeRSA();
    key.setOptions({
        encryptionScheme: "pkcs1"
    });
    key.importKey(publicKeyStr, "pkcs8-public");

    return key.encrypt(toEncrypt, "base64");
}

const fieldsToEncrypt = [
    "dni",
    "email",
    "firstName",
    "lastName",
    "customerNumber",
    "cuit",
    "cuil",
    "businessName",
    "phone",
    "passport",
    "libretaCivica",
    "libretaEnrolamiento",
    "cedulaDiplomatica",
    "cedulaIdentidad"
];

export const encryptCustomer = (customer, publicKey) => {
    const encryptedCustomer = { ...customer };
    for (const field of fieldsToEncrypt) {
        if(typeof customer[field] === "string") {
            encryptedCustomer[field] = encrypt(customer[field], publicKey);
        }
    }
    return encryptedCustomer;
};

