import React from 'react';
import PropTypes from "prop-types";
import i18next from 'i18next';

export default class Alert extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        if (props.onClick && typeof props.onClick === 'function') {
            this.onClick = props.onClick;
        }

        setTimeout(_=>{
            if(this.props.onTimeoutClose)
                this.props.onTimeoutClose();
        }, 10 * 1000);
    }

    render() {
        const {text, buttonText} = this.props;
        return (
                <div className={`alert__container`}>
                    <div className={'alert__title-container'}>
                         <h1 className="alert__title">
                            <i className='fa fa-info-circle'></i> {text}
                        </h1>
                    </div>
                    <div className={"alert__button-container"}>
                        {
                                    buttonText && <button onClick={this.onClick} className={'alert__button'}> {buttonText} </button>
                        }
                    </div>
               </div>
            );
    }
}

Alert.propTypes = {
    text: PropTypes.string,
    buttonText: PropTypes.string,
    onClick: PropTypes.func,
    onTimeoutClose: PropTypes.func
};

Alert.defaultProps = {
    text: i18next.t("Alert.ALERT"),
    buttonText: undefined,
    onClick: undefined,
    onTimeoutClose: undefined
};
