import axios from 'axios';

/**
 * Intercept requests before they are sent
 */
axios.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem('token');
    if(token){
        config.headers.authorization = token
    }
    return config;
}, function (error) {
    return Promise.reject(error)
});

/**
 * Intercepts all the responses
 */
axios.interceptors.response.use(function (response) {
    const auth = response.headers.authorization;
    if(auth !== null && auth !== undefined){
        sessionStorage.setItem('token', auth)
    }
    return response;
}, function (error) {
    return Promise.reject(error)
});