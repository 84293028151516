import React from 'react'
import * as Analytics from '../utils/Analytics';

export default (props)=>{
    const click = ev => {
        if (props.id)
            Analytics.event(props.id);
        if(props.onClick)
            return props.onClick(ev);
    };
    return <button {...props} onClick={click}/>;
};
