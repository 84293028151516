import React, { Component } from 'react';
import { AppConsumer } from '../components/AppContext';
import TurnView from "./TurnView";
import Videocall from "./Videocall";
import {NotificationManager} from "react-notifications";
import withTracker from "../utils/withTracker";
import ModalDialog from "../components/ModalDialog";


class VideocallWithTurnView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showVideoCall: false,
            showAttentionTimeModal: false,
        };
        this.videocallIframe = null;

    }

    onVideoCallStarted(){
        this.setState({ showVideoCall: true });
    }

    userLeftTab(){
        const { t } = this.context;
        if(this.state.showVideoCall) NotificationManager.info(t("VideocallWithTurnView.USER_LEFT_TAB"));
    }

    onVideoCallEnded(){
        const { t } = this.context;
        this.setState({ showVideoCall: false });
        console.log("Videocall ended. Will notify user");
        NotificationManager.info(t("VideocallWithTurnView.VIDEOCALL_ENDED"));
    }

    onAttentionTimeAlert(){
        this.setState({ showAttentionTimeModal: true });
    }

    render() {
        const { showVideoCall } = this.state;
        const { showAttentionTimeModal } = this.state;
        const {state: {actualTurn}} = this.context;
        const { t } = this.context;

        const turnFinished = actualTurn && (actualTurn.hasOwnProperty('finishTime') && actualTurn.finishTime !== "");
        return(
            <div>
                {
                    <div className={!showVideoCall ? "" : "hideTurn"}>
                        <TurnView isWaitingForVideocall onAttentionTimeAlert={() => this.onAttentionTimeAlert()} onVideocallStarted={() => this.onVideoCallStarted()} onVideoCallEnded={() => this.onVideoCallEnded()} iframe={this.videocallIframe}  turn={actualTurn}/>
                    </div>
                }
                {
                    this.context.state.actualTurn.status &&
                    <>
                        <div style={{ "display": (showVideoCall ? "block" : "none") }}>
                            <Videocall onVideocallStarted={_ => this.onVideoCallStarted()} userLeftTab={_ => this.userLeftTab()} iframeRef={(el) => (this.videocallIframe = el)}
                                onVideocallEnded={_ => this.onVideoCallEnded()} attentionFinished={turnFinished} />
                        </div>
                    </>
                }
                {
                    showVideoCall &&
                    <div className={showAttentionTimeModal? "" : "hideTurn"}>
                        <ModalDialog
                            description={this.context.state.actualTurn.jsonDetails.queue.attentionTimeNotificationText}
                            type={"accept"}
                            primaryLabel={t("TurnView.ACCEPT")}
                            onConfirm={() => {
                                this.setState({ showAttentionTimeModal: false });
                            }}
                            onCancel={() => {
                                this.setState({ showAttentionTimeModal: false });
                            }}
                        />
                    </div>
                }
            </div>
        );
    }
}

VideocallWithTurnView.contextType = AppConsumer;
export default withTracker(VideocallWithTurnView);
