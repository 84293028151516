/**
 * Check if an object is empty
 * @param obj
 */
export function isEmpty(obj){
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function anchorTime(estimate, timeUnit = "minutos", minimumUnit = "minuto",
                           overflowUnit = "horas", overflowMinimumUnit = "hora") {
    let unit = timeUnit;
    let time = parseFloat(estimate);
    if (time >= 50 && time <= 60) {
        return `1 ${overflowMinimumUnit}`;
    }
    if (time < 1) {
        return `1 ${minimumUnit}`;
    }
    if (time > 60) {
        time = time / 60;
        unit = overflowUnit;
    }
    const matrix = [
        [1, 2, 3],
        [3, 5],
        [5, 10, 15],
        [10, 20, 30],
        [30, 40, 50]
    ];
    for (let j = 0; j < matrix.length; j++) {
        const row = matrix[j];
        for (let i = 0; i < row.length - 1; i++) {
            const lower = row[i];
            const upper = row[i + 1];
            if (time >= lower && time < upper) {
                return `${lower}-${upper} ${unit}`;
            }
        }
    }
}