import TagManager from 'react-gtm-module'
import trackedEvents from './trackingData/trackedEvents.json'
import trackedPages from './trackingData/trackedPages.json'

let initialized = false;
let onInitCallbacks = [];

const initialize = (trackingId)=>{
    if(initialized) return;

    TagManager.initialize({gtmId: trackingId});
    initialized=true;
    onInitCallbacks.forEach(cb => cb());
    onInitCallbacks = [];
};

const deinitialize = _ => {
    initialized = false;
};

const event = (evName) => {
    if(!initialized) {
        onInitCallbacks.push(event.bind(window, evName));
        return;
    }

    const trackingInfo = trackedEvents[evName];
    if (!trackingInfo || !trackingInfo.track)
        return;

    console.debug(evName, "being tracked as", trackingInfo.eventAction);

    TagManager.dataLayer({
        dataLayer:{
            eventCategory: trackingInfo.eventCategory,
            eventAction: trackingInfo.eventAction,
            eventLabel: trackingInfo.eventLabel,
            eventValue: '',
            event: 'eventClick'
        }
    });
};

const pageChange = (page) =>{
    if(!initialized) {
        onInitCallbacks.push(pageChange.bind(window, page));
        return;
    }

    const trackingInfo = trackedPages.find(p => {
        return new RegExp("^" + p.pattern + "$").test(page);
    });
    if(!trackingInfo || !trackingInfo.track) return;
    console.debug(page, "being tracked as", trackingInfo.name);

    TagManager.dataLayer({
        dataLayer:{
            eventCategory: trackingInfo.eventCategory,
            eventAction: 'Pageview',
            eventLabel: trackingInfo.eventLabel,
            eventValue: '',
            event: 'eventClick'
        }
    });
};

export {initialize, deinitialize, event, pageChange};