import React from 'react'
import PropTypes from "prop-types";

export default class TitleBar extends React.Component {
  
    render() {
        return (
            <div className={`title-bar title-bar--center`}>
                <h1 className="title-bar__title">{this.props.title}</h1>
            </div>
        )
    }
}

TitleBar.propTypes = {
    title: PropTypes.string,
};

TitleBar.defaultProps = {
    title: ""
};
