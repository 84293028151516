import React from 'react';
import img_error from '../img/img_error.png';
import { AppConsumer } from "../components/AppContext";

const NotFound = () => (
    <AppConsumer>
    {({t}) => (
            <div className="turnView">
                <div className="content finished">
                    <div className="viewSelectQueue__imgContainer">
                        <div className="card card-info">
                            <img alt={t("NotFound.NOT_FOUND_IMAGE")} src={img_error} className={'img-responsive'} />
                            <h2 className="deb-main-title" id="notFoundTitle">{ t("NotFound.NOT_FOUND_TITLE") }</h2>
                            <p className="deb-text deb-text--center" id="notFoundText">
                                { t("NotFound.NOT_FOUND_TEXT") }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )}
        </AppConsumer>
);

export default NotFound;