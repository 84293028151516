import React from 'react'
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

export default class TopBar extends React.Component {
    clickLinkBackHandler = (ev) => {
        if (typeof this.props.linkHandler === "function") {
            ev.preventDefault();
            this.props.linkHandler(ev);
        }
    };

    render() {
        if (this.props.linkBack || this.props.linkHandler) {
            const linkBackTo = this.props.linkBack ? this.props.linkBack : "/";
            return (
                <div className={`top-bar`}>
                    <Link to={linkBackTo} className="top-link " onClick={this.clickLinkBackHandler}>
                            <button className="button-clear deb-back-button" id={this.props.id}>
                                <i className="fa fa-chevron-left"></i>
                            </button>
                    </Link>
                    <h1 className="top-bar__title">{this.props.title}</h1>
                    <div className="top-bar__align"></div>
                </div>
            );
        } else {
            return (
                <div className={`top-bar top-bar--center`}>
                    <h1 className="top-bar__title">{this.props.title}</h1>
                </div>
            )
        }
    }
}

TopBar.propTypes = {
    title: PropTypes.string,
    linkBack: PropTypes.string,
    id: PropTypes.string,
    linkHandler: PropTypes.func
};

TopBar.defaultProps = {
    title: "",
    id: ""
};
