import React from "react";
import { AppConsumer, AppContext } from '../components/AppContext';
import i18next from 'i18next';
const languages = [
  {
    key: "es",
    label: "LANGUAGE_SELECTOR.SPANISH",
  },
  {
    key: "en",
    label: "LANGUAGE_SELECTOR.ENGLISH",
  },
  {
    key: "pt",
    label: "LANGUAGE_SELECTOR.PORTUGUESE",
  },
  {
    key: "fr",
    label: "LANGUAGE_SELECTOR.FRENCH",
  },

]
export default class LanguageSelector extends React.Component {
  constructor(props){
    super(props);
    this.state = {open: false}
    this.ref = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(event) {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.setState({open:false});
    }
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  };

  changeLang = (lang) => {
    console.log("change lang to: ", lang);
    i18next.changeLanguage(lang);
    this.setState({open:false});
  }
  toggleDropdown = ()=>{
    this.setState({open:!this.state.open});
  }
  render() {
    return (
      <AppConsumer>
        {({t})=> {return (
          <button ref={this.ref} aria-label={"Language Selector"} className="language-selector-container" onClick={this.toggleDropdown}>
            <i className={`fa fa-globe fa-2x`}/>
            {this.state.open && 
              <div className="language-selector-dropdown">
                <ul className="deb-text">
                  {languages.map((lang)=>{
                    return (
                      <li key={lang.key} onClick={this.changeLang.bind(this, lang.key)}>
                        {t(lang.label)}
                      </li>
                    );
                  })}
                </ul>
              </div>
            }
          </button>
        )}}
      </AppConsumer>
    );
  }
}
