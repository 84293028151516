import React, {Component} from 'react';
import api from '../requests/api';
import debMarkerIconUrl from '../img/debmarker.png';
import debGpsIconUrl from '../img/debgps.png';
import {AppConsumer} from '../components/AppContext';
import Loader from '../components/Loader';
import Branches from "./Branches";
import {isEmpty} from "../helpers";
import i18next from 'i18next';
import TrackedButton from "../components/TrackedButton";

class BranchesMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingMap: true
        }
    }

    static contextType = AppConsumer;

    map;
    info;
    markers;
    gpsMarker;

    static mapStyle = [
        {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        }
    ];

    static getInfoWindowContentForBranch = (branch) => {
        const waitingTime = Branches.getBranchOrMBranchAverageWaitingTime(branch, branch.mBranch);
        const waitingTimeElement = `<li class="info-window__data-list-item">${i18next.t("BranchesMap.WAIT_TIME", {waitingTime})}</li>`;

        const waitingTurns = Branches.getBranchOrMBranchWaitingTurns(branch, branch.mBranch);
        const waitingTurnsElement = `<li class="info-window__data-list-item">${i18next.t("BranchesMap.TURNS_AHEAD", {waitingTurns})}</li>`;

        const direccion = Branches.getBranchDireccion(branch, branch.mBranch);
        const direccionElement = `<li class="info-window__data-list-item">${direccion}</li>`;

        let openDetails = Branches.getBranchOpenDetails(branch, true);
        if (openDetails && openDetails.length) openDetails = openDetails.map(day => `${day.day} ${day.hours.join("<br/>")}`).join("<br/>");
        const openDetailsElement = `<li class="info-window__data-list-item">${openDetails}</li>`;

        const branchClosedElement = `<div class="info-window__closed-warn">${i18next.t("BranchesMap.BRANCH_CLOSED")}</div> 
                                    ${openDetails && openDetailsElement}`;

        return `<div class="info-window">
                    <h3 class="info-window__title">${Branches.getBranchTitle(branch)}</h3>
                    <div class="info-window__data-container">
                        ${ !Branches.isOpen(branch) ? branchClosedElement : ` 
                        <ul class="info-row">
                            ${ waitingTime ? waitingTimeElement : ''}
                            ${ waitingTurns ? waitingTurnsElement : ''}
                            ${ direccion ? direccionElement : ''}
                            ${ openDetails ? openDetailsElement : ''}
                        </ul>
                        `}
                    </div>
                </div>`
    };

    onMarkerClicked = (marker, branch, t) => {
        // eslint-disable-next-line no-undef
        if (! (this.info instanceof google.maps.InfoWindow) ) {
            // eslint-disable-next-line no-undef
            this.info = new google.maps.InfoWindow({});
        }
        this.info.close();
        this.info.setContent(BranchesMap.getInfoWindowContentForBranch(branch));
        this.info.open(this.map, marker);

        if (typeof this.props.selectBranchHandler === "function") {
            this.props.selectBranchHandler(branch).then(_=>{
                // Reload branch info after fetching it
                this.info.setContent(BranchesMap.getInfoWindowContentForBranch(branch));
            });
        }

        this.info.addListener("closeclick", this.props.selectBranchHandler.bind(this, {}));
    };

    renderBranchesOnMap = () => {
        const {branches, selectedBranch} = this.props;
        if (!(branches.length > 0)) {
            console.warn("No branches to render in map");
            return;
        }

        const markers = [];

        branches.forEach((br) => {
            if (!Branches.branchHasGeolocationData(br)) {
                return;
            }
            // eslint-disable-next-line no-undef
            const marker = new google.maps.Marker({
                map: this.map,
                position: Branches.getBranchGeolocationData(br),
                // eslint-disable-next-line no-undef
                icon: {url:debMarkerIconUrl, scaledSize: new google.maps.Size(27, 43)}
            });

            marker.addListener('click', this.onMarkerClicked.bind(this, marker, br));
            if (selectedBranch.id === br.id) {
                this.onMarkerClicked(marker, br);
                const {position:markerLatLng} = marker;
                this.map.setCenter(markerLatLng);
            }
            markers.push(marker);
        });

        this.markers = markers;

        if (isEmpty(selectedBranch) || !Branches.branchHasGeolocationData(selectedBranch)) {
            this.renderUserPosition().then(this.centerOnClosestBranch).catch(this.fitAllBranchesInMap)
        } else {
            this.renderUserPosition();
        }
    };

    renderMap = () => {
        if (!window.google || !window.google.maps) {
            return;
        }
        // eslint-disable-next-line no-undef
        if (!(this.map instanceof google.maps.Map)) {
            // eslint-disable-next-line no-undef
            this.map = new google.maps.Map(document.getElementById('branches-map'), {
                zoom: 13,
                disableDefaultUI: true,
                styles: BranchesMap.mapStyle
            });
        }

        this.renderBranchesOnMap();
    };

    renderUserPosition = () => {
        const drawUserPosition = (pos) => {
            const {coords: {latitude:lat, longitude:long}} = pos;
            // eslint-disable-next-line no-undef
            if (this.gpsMarker instanceof google.maps.Marker) {
                this.gpsMarker.setPosition({lat: lat, lng: long});
            } else {
                // eslint-disable-next-line no-undef
                this.gpsMarker = new google.maps.Marker({
                    position: {lat:lat, lng: long},
                    map: this.map,
                    // eslint-disable-next-line no-undef
                    icon: {url:debGpsIconUrl, scaledSize: new google.maps.Size(20, 20), anchor: new google.maps.Point(10, 10)}
                });
            }
        };

        return new Promise((resolve, reject) => {
            Branches.getGeolocationIfAvailable(async (pos) => {
                if (pos && pos.coords) {
                    drawUserPosition(pos);
                    resolve(pos);
                } else {
                    reject();
                }
            }, reject);
        });
    };

    fitAllBranchesInMap = () => {
        if (this.markers.length === 1) {
            this.map.setCenter(this.markers[0].position);
            this.map.setZoom(16);
            return;
        }
        // eslint-disable-next-line no-undef
        let bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < this.markers.length; i++) {
            bounds.extend(this.markers[i].position);
        }
        this.map.fitBounds(bounds);
        if (this.map.getZoom() > 16) {
            this.map.setZoom(16);
        }
    };

    centerOnClosestBranch = async (pos) => {
        const branchToCenterMapOn = await this.getClosestBranch(pos);
        if (branchToCenterMapOn) {
            const branchLatLng = Branches.getBranchGeolocationData(branchToCenterMapOn);
            this.map.setCenter({lat: branchLatLng.lat, lng: branchLatLng.lng});
        } else {
            throw new Error('Failed to get closest branch to center on it.');
        }
    };

    getClosestBranch = async (pos) => {
        const {coords: {latitude:lat, longitude:long}} = pos;
        const {branches} = this.props;
        const {selectedCompany: companyName} = this.context.state;

        const resp = await api.branches().computeDistances(companyName, lat, long);
        // Response format {id: 12, distanceInMeters: 1200}
        const distances = {};

        resp.data.forEach((d) => {
            distances[d.id] = d.distanceInMeters;
        });

        return branches.reduce((currentClosest, branch) => {
            if (!currentClosest) {
                return branch;
            } else if (!distances[branch.id]) {
                return currentClosest;
            } else if (!distances[currentClosest.id]) {
                return branch;
            } else if (distances[branch.id] < distances[currentClosest.id]) {
                return branch;
            } else {
                return currentClosest;
            }
        });
    };

    componentDidMount() {
        if ((typeof window.google === "undefined") || !window.google.maps) {
            api.scripts().loadMapApi().then(() => {
                this.setState({loadingMap: false}, this.renderMap);
                console.info("External google maps script loaded");
            });
        } else if (this.state.loadingMap === true) {
            this.setState({loadingMap: false}, this.renderMap);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {branches: prevBranches} = prevProps;
        const {branches: currBranches} = this.props;

        if (prevBranches !== currBranches) {
            this.renderMap();
        }
    }

    render() {
        const {selectedBranch} = this.props;
        const {loadingMap} = this.state;

        return (
            <AppConsumer>
            {({t}) => (
                <>
                    <div className="card card--full-width card--full-height card--fixed card--map__container">
                        <Loader fullscreen={false} loading={loadingMap}>
                            <div id={`branches-map`} className="card--map__map">

                            </div>
                        </Loader>
                    </div>
                    <div className="row">
                        {isEmpty(selectedBranch) ?
                            <button
                                className="button button-calm button-block button-primary button-primary--fixed"
                                disabled='disabled'>
                                {t("BranchesMap.SELECT_BRANCH")}
                            </button>
                            :
                            <TrackedButton   className="button button-calm button-block button-primary button-primary--fixed"  
                            disabled={(!(Branches.isOpen(selectedBranch)) && selectedBranch.mBranch &&
                            selectedBranch.mBranch.enqueueAllowedWhileClosed === false) ? 'disabled' : false}
                            onClick={this.props.branchSelectedEventHandler}
                            id="select_branch"
                            >
                            {t("BranchesMap.GET_TURN")}
                            </TrackedButton>
                        }
                        <button
                            className="button button-calm button-block button-secondary button-secondary--fixed"
                            onClick={this.props.toggleViewHandler}>
                            <i className="fa fa-list"></i>
                        </button>
                    </div>
                </>
            )}
    </AppConsumer>
        )
}
}

export default BranchesMap;