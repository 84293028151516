import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import unorm from 'unorm';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppProvider } from './components/AppContext';
import { I18nextProvider } from "react-i18next";
import'./i18n';


ReactDOM.render(
    <Router basename={process.env.PUBLIC_URL}>
    <I18nextProvider>
        <AppProvider>
            <App />
        </AppProvider>
    </I18nextProvider>
    </Router>,
    document.getElementById('app')
);

serviceWorker.register({
    onUpdate: reg => {
        document.dispatchEvent(new CustomEvent('filesUpdated', {
            detail: {swRegistration: reg.waiting}
        }));
    }
});
