/**
 * Adapted from https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React, { useEffect } from "react";
import * as Analytics from './Analytics';

const withTracker = (WrappedComponent) => {
    const trackPage = page => {
        Analytics.pageChange(page);
    };

    const HigherOrderComponent = props => {
        useEffect(() => {if(props.location !== undefined) trackPage(props.location.pathname)} , [
                props.location
        ]);

        return <WrappedComponent {...props} />;
    };

    return HigherOrderComponent;
};

export default withTracker;
