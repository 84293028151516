import React from 'react';
import PropTypes from "prop-types";
import TrackedButton from "./TrackedButton";
import ReactMarkdown from 'react-markdown';
import i18next from 'i18next';

export default class ModalDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            animation: 'open'
        };

        if (props.onConfirm && typeof props.onConfirm === 'function') {
            this.onConfirm = props.onConfirm;
        }
        if (props.onCancel && typeof props.onCancel === 'function') {
            this.onCancel = props.onCancel;
        }

        this.confirmId = props.confirmId
        this.cancelId = props.cancelId

        this.closeDialog = this.closeDialog.bind(this);
    }

    closeDialog(callback, time = 600, ev) {
        ev.stopPropagation();
        this.setState({animation: 'close'});
        if (typeof callback === "function") {
            setTimeout(callback, time);
        }
    }

    render() {
        let secondaryButtonType = 'cancel';
        let primaryButtonType = 'confirm';
        if (this.props.type === "warn") {
            secondaryButtonType = 'subtle';
            primaryButtonType = 'cancel';
        } else if (this.props.type === "persuade") {
            secondaryButtonType = 'subtle';
            primaryButtonType = 'confirm';
        } else if (this.props.type === "accept") {
            primaryButtonType = 'confirm';
            secondaryButtonType = 'hidden';
        }
        return (
            <div className={`modal-dialog__background modal-dialog--${this.state.animation}`} onClick={this.closeDialog.bind(this, this.onCancel, 600)}>
                <div className={`modal-dialog__container`}>
                    <div className="modal-dialog__main-container">
                        <h1 className="modal-dialog__title">
                            {this.props.title}
                        </h1>
                        <p className="modal-dialog__info">
                            {
                                this.props.useMarkdown ?
                                    <ReactMarkdown>
                                        {this.props.description}
                                    </ReactMarkdown>
                                    :
                                    this.props.description
                            }
                        </p>
                    </div>
                    <div className="modal-dialog__buttons-container">
                        <TrackedButton className={`modal-dialog__button modal-dialog__${secondaryButtonType}-button`}
                                id={this.cancelId}
                                onClick={this.closeDialog.bind(this, this.onCancel, 600)}>
                            {this.props.secondaryLabel}
                        </TrackedButton>
                        <TrackedButton className={`modal-dialog__button modal-dialog__${primaryButtonType}-button`}
                                id={this.confirmId}
                                onClick={this.closeDialog.bind(this, this.onConfirm, 300)}>
                            {this.props.primaryLabel}
                        </TrackedButton>
                    </div>
                </div>
            </div>
        );
    }

}


ModalDialog.propTypes = {
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    primaryLabel: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    secondaryLabel: PropTypes.string,
    useMarkdown : PropTypes.string,
    type: PropTypes.oneOf(['warn', 'confirm', 'persuade', 'accept'])
};

ModalDialog.defaultProps = {
    primaryLabel: i18next.t("ModalDialog.YES"),
    secondaryLabel: i18next.t("ModalDialog.NO"),
    type: "confirm"
};
