import React, { Component } from 'react';
import api from '../requests/api.js';
import { AppConsumer } from '../components/AppContext';
import Loader from '../components/Loader';
import img from '../img/company_img.svg';
import withTracker from "../utils/withTracker";

class SelectCompany extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            selected: '',
            companies: [],
            loading: true,
            customCss: ''
        };
    }

    /**
     * Gets all the available companies
     * @returns {Promise<void>}
    */
    async getCompanies() {
        try {
            return await api.companies().getAll();
        } catch (err) {
            console.log(err);
        }
    }

    /**
     * Set the selected company on the state
    */
    handleChange(event) {
        this.setState({ selected: event.target.value });
    }

    async componentDidMount() {
        const { actions } = this.context;
        actions.resetActualTurn(false);
        if(this.context.state.profileList === undefined) {
            actions.createProfileList();
        }
        const req = await this.getCompanies();
        const companies = req ? req.data : [];
        companies.sort((c1, c2) => {
            return c1.name.toLowerCase().localeCompare(c2.name.toLowerCase());
        });
        this.setState({ companies: companies, loading: false, selected: '', customCss: '' });
    }


    render() {
        const { selected, companies, loading } = this.state;
        const hideBar = ".menu-bar {display: none;}"
        return (
            <Loader loading={loading} fullscreen={false}>
            <style>
                 {hideBar}
            </style>
                <div className="viewSearchCode">
                    <div className="content">
                        <div >
                            <AppConsumer>
                                {({ state, actions, t }) => (
                                    <div className="container-body">
                                        <div className="card card-info">
                                            <form
                                                className="col search-container"
                                                onSubmit={actions.selectCompany.bind(
                                                    this,
                                                    selected === '' && state.selectedCompany !== ''
                                                        ? state.selectedCompany
                                                        : selected
                                            )}>
                                                <img
                                                    src={img}
                                                    alt={t("SelectCompany.SELECT_COMPANY_IMAGE")}
                                                    className="img-responsive viewSelectQueue__img viewSelectQueue__maxImg"
                                                />
                                                <h2 className="deb-main-title text-center">{ t("SelectCompany.SELECT_COMPANY_POLITE") }</h2>
                                                <div className="list list-inset">
                                                    <div className="box-select">
                                                        <select
                                                            name="company"
                                                            className="app-select"
                                                            value={selected}
                                                            onChange={this.handleChange}>
                                                            {selected === '' && (
                                                                <option value="-">{ t("SelectCompany.SELECT_COMPANY") }</option>
                                                                )}
                                                            {companies.map((company, i) => (
                                                                <option key={i} value={company.name}>
                                                                    {company.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <button
                                                    disabled={selected === '' ? 'disabled' : false}
                                                    className="button button-calm button-block button-primary">
                                                    { t("SelectCompany.CONTINUE") }
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </AppConsumer>
                        </div>
                    </div>
                </div>
            </Loader>
         );
    }
}

SelectCompany.contextType = AppConsumer;
export default withTracker(SelectCompany);
