import React, { Component } from 'react';
import Branches from "./Branches";
import Profile from "./Profile";
import {AppContext} from "../components/AppContext";
import Loader from "../components/Loader";

export default class BranchesWithProfile extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showProfileForm: false,
            fetchingTurn: false
        }
    }

    goToConfirm = ()=>{
        this.props.history.push('/confirmar');
    };

    profileFormClose = () => {
        const {state} = this.context;
        if (state.profile) {
            this.goToConfirm();
        } else {
            // The user tried closing the form, so
            this.setState({showProfileForm: false, preSelectedBranch: state.selectedBranch});
        }
    };

    branchSelectedHandler = () => {
        const {state} = this.context;
        // If the profile is empty, it takes you to the profile form
        if (!state.profile || Object.keys(state.profile).length === 0) {
            this.setState({showProfileForm: true});
        } else {
            this.goToConfirm();
        }
    };

    skipFormCompletion = () => {
        const {state} = this.context;
        if (state.selectedBranch) {
            this.goToConfirm();
        }
    };

    render() {
        return (
            <>
                { this.state.fetchingTurn && <Loader fullscreen={true} loading={true} /> }
                { this.state.showProfileForm ? (
                    <Profile formClose={this.profileFormClose} skipHandler={this.skipFormCompletion}
                             saveHandler={this.goToConfirm}/>
                ) : (
                    <Branches branchSelectedHandler={this.branchSelectedHandler} selected={this.state.preSelectedBranch}/>
                )}
            </>
        );
    }
}

BranchesWithProfile.contextType = AppContext;